// ToDo: English version missing

import React from 'react';
import { graphql } from 'gatsby';
import { getCookieConsentValue, Cookies } from 'react-cookie-consent';
import { useIntl, FormattedMessage } from 'gatsby-plugin-react-intl';

import { Box, Typography } from '@mui/material';

import Layout from '../components/layout/Layout';
import Seo from '../components/Seo';
import { Markdown, Container, Button } from '../components/theme';

function PrivacyPage({
  data: {
    allStrapiPrivacyStatement: { nodes },
  },
}) {
  const { privacyText } = nodes[0];
  const { privacyText2 } = nodes[0];

  const intl = useIntl();

  return (
    <Layout lightTheme>
      <Container narrow>
        <Markdown source={privacyText.data.Text} />
        <Box>
          {getCookieConsentValue('kki-consent') && (
            <Typography variant="body1">
              {getCookieConsentValue('kki-consent') === 'true'
                ? intl.formatMessage({ id: 'privacy.trackingAgreed' })
                : intl.formatMessage({ id: 'privacy.trackingDeclined' })}
            </Typography>
          )}
        </Box>
        <Typography gutterBottom variant="body2">
          <FormattedMessage id="privacy.consent" />
          <Box
            component="span"
            sx={{
              display: 'block',
              '& > button': {
                margin: '10px 20px 10px 0',
              },
            }}
          >
            {getCookieConsentValue('kki-consent') === 'false' ? (
              <Button
                color="secondary"
                size="small"
                variant="contained"
                onClick={() => {
                  Cookies.set('kki-consent', 'true');
                  window.location.reload();
                }}
              >
                {intl.formatMessage({ id: 'privacy.agree' })}
              </Button>
            ) : (
              <Button
                color="secondary"
                size="small"
                variant="contained"
                onClick={() => {
                  Cookies.set('kki-consent', 'false');
                  window.location.reload();
                }}
              >
                {intl.formatMessage({ id: 'privacy.decline' })}
              </Button>
            )}
          </Box>
        </Typography>
        <Markdown source={privacyText2.data.Text2} />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    allStrapiPrivacyStatement {
      nodes {
        privacyText: Text {
          data {
            Text
          }
        }
        privacyText2: Text2 {
          data {
            Text2
          }
        }
      }
    }
  }
`;

export default PrivacyPage;

export function Head() {
  return <Seo title="Datenschutzerklärung" description="Unsere Datenschutzerklärung" />;
}
